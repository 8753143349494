import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import { serverUrl } from "../../service";
import Datatable from "./components/Datatable";
import TableTitles from "./components/TableTitles";

const dataTableOptions = pm => {
  let url = pm.url !== undefined ? pm.url : "",
    requestType = pm.requestType !== undefined ? pm.requestType : "GET",
    ajax = pm.ajax !== undefined ? pm.ajax : null,
    tableDatas = pm.tableDatas !== undefined ? pm.tableDatas : null,
    columns = pm.columns !== undefined ? pm.columns : null,
    eqColumns = pm.eqColumns !== undefined ? pm.eqColumns : [], //不参与搜索的字段名和是否是
    timeColumns = pm.timeColumns !== undefined ? pm.timeColumns : [], //时间字段名
    columnDefs = pm.columnDefs !== undefined ? pm.columnDefs : null,
    searchCols = pm.searchCols !== undefined ? pm.searchCols : null,
    params = pm.params !== undefined ? pm.params : {},
    dataTableParams = pm.dataTableParams !== undefined ? pm.dataTableParams : null, //获取dataTable的原始参数
    data = pm.data !== undefined ? pm.data : null,
    order = pm.order !== undefined ? pm.order : [],
    paging = pm.paging !== undefined ? pm.paging : true,
    ordering = pm.ordering !== undefined ? pm.ordering : true,
    searching = pm.searching !== undefined ? pm.searching : true,
    // eqPairs是新增加的全局表搜索条件数组，条件间关系为and
    // "eqPairs": [
    //     {
    //       "name": "dataType",                   	//表字段名
    //       "value": 1	                            //条件值（非空时使用=判定）
    //       "values": [1,2]	                      //多条件值（value为空且values非空，使用in()判定）
    //     }
    //   ]
    eqPairs = pm.eqPairs !== undefined ? pm.eqPairs : null,
    processing = pm.processing !== undefined ? pm.processing : true,
    serverSide = pm.serverSide !== undefined ? pm.serverSide : true,
    drawCallback = pm.drawCallback !== undefined ? pm.drawCallback : null,
    dom = pm.dom !== undefined ? pm.dom : null,
    buttons = pm.buttons !== undefined ? pm.buttons : null;

  let options = { processing, serverSide, drawCallback };

  let dataFun = dtParams => {
    if (dataTableParams) {
      dataTableParams(dtParams);
    }

    if (eqColumns) {
      dtParams.eqColumns = eqColumns;
    }

    if (timeColumns) {
      dtParams.timeColumns = timeColumns;
    }

    if (eqPairs) {
      dtParams.eqPairs = eqPairs;
    }

    let paramsObj = params;
    if (params instanceof Function) {
      paramsObj = params();
    }

    let paramsStr = "";
    Object.keys(paramsObj).forEach(key => {
      paramsStr += key + "=" + paramsObj[key] + "&";
    });

    paramsStr += "dataTable=" + encodeURIComponent(JSON.stringify(dtParams));
    return paramsStr;
  };

  options.ajax = {
    paging: paging,
    searching: searching,
    url: serverUrl + url,
    type: requestType,
    eqPairs: eqPairs,
    xhrFields: { withCredentials: true },
    headers: { Accept: "application/json, text/plain, */*", ws_auth_token: "123" },
    data: dataFun,
    dataFilter: data => {
      const json = JSON.parse(data);
      if (json.code == 200) {
        return JSON.stringify(json.data);
      } else {
        let emptyData = { data: [], draw: 0, error: "", recordsFiltered: 0, recordsTotal: 0 };
        return JSON.stringify(emptyData);
      }
    },
  };

  if (data) {
    delete options.ajax;
    options.data = data;
  } else if (tableDatas) {
    delete options.ajax;
    options.data = tableDatas();
  } else if (ajax) {
    options.ajax = ajax;
  }

  if (columns) {
    for (let i = 0; i < columns.length; i++) {
      let data = columns[i];
      if (!data.render) {
        data.render = (data, type, row, meta) => {
          if (data == null) return '<div style="text-align:center">--</div>';
          return '<div style="text-align:center">' + data + "</div>";
        };
      }
    }
    options.columns = columns;
  }

  if (columnDefs) {
    options.columnDefs = columnDefs;
  }

  if (searchCols) {
    options.searchCols = searchCols;
  }

  if (order) {
    options.order = order;
  }

  if (buttons) {
    if (buttons instanceof Function) {
      options.buttons = buttons();
    } else {
      options.buttons = buttons;
    }
  }

  if (dom) {
    options.dom = dom;
  }

  options.paging = paging;
  options.ordering = ordering;
  options.searching = searching;

  return options;
};

const dts = (dt, fm) => (dt ? moment(dt).format(fm || "YYYY-MM-DD HH:mm:ss") : "--");

const DivCenter = ({ width, children }) => <div {...{ children, style: { width, margin: "0 auto", textAlign: "center" } }} />;

const TextEllipsis = props => {
  const { width, tooltip, style: divStyle, ...otherDivProps } = props;
  const tooltipProps = {
    placement: "right",
    arrowPointAtCenter: true,
    title: otherDivProps.children,
    ...tooltip,
  };
  const style = {
    width,
    margin: "0 auto",
    overflow: "hidden",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    ...divStyle,
  };
  return (
    <Tooltip {...tooltipProps}>
      <div style={style} {...otherDivProps} />
    </Tooltip>
  );
};

export { Datatable, TableTitles, dataTableOptions, dts, DivCenter, TextEllipsis };
