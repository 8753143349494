import loadable from "react-loadable";
import loading from "../../common/ui/components/Loading";

const Load = component => loadable({ loader: () => component, loading });

const routesCfg = [
  {
    name: "AirCtrlEfficiencyAnalysis",
    path: "/SDNPowerAdmin/AirCtrlEfficiencyAnalysis",
    component: Load(import("./SDNImportantAnalysis/AirCtrlEfficiencyAnalysisPage")),
  },
  {
    name: "AirCtrlDataMonitor",
    path: "/SDNPowerAdmin/AirCtrlDataMonitor",
    component: Load(import("./SDNImportantAnalysis/AirCtrlDataMonitorPage")),
  },
  {
    name: "AirZipEfficiencyAnalysis",
    path: "/SDNPowerAdmin/AirZipEfficiencyAnalysis",
    component: Load(import("./SDNImportantAnalysis/AirZipEfficiencyAnalysisPage")),
  },
  {
    name: "AirZipDataMonitor",
    path: "/SDNPowerAdmin/AirZipDataMonitor",
    component: Load(import("./SDNImportantAnalysis/AirZipDataMonitorPage")),
  },
  {
    name: "SDNPowerAnalysis",
    path: "/SDNPowerAdmin/SDNPowerAnalysis",
    component: Load(import("./SDNPowerAnalysis/SDNPowerAnalysisPage")),
  },
  {
    name: "SDNDemandAnalysis",
    path: "/SDNPowerAdmin/SDNDemandAnalysis",
    component: Load(import("./SDNDemandAnalysis")),
  },
  {
    name: "SDNResponseConfig",
    path: "/SDNPowerAdmin/SDNResponseConfig",
    component: Load(import("./SDNResponse/SDNResponseConfig")),
  },
  {
    name: "SDNResponsePlan",
    path: "/SDNPowerAdmin/SDNResponsePlan",
    component: Load(import("./SDNResponse/SDNResponsePlan")),
  },
  {
    name: "SDNResponseHistory",
    path: "/SDNPowerAdmin/SDNResponseHistory",
    component: Load(import("./SDNResponse/SDNResponseHistory")),
  },  
  {
    name: "SDNStatementStatistics",
    path: "/SDNPowerAdmin/SDNStatementStatistics",
    component: Load(import("./SDNStatementStatistics/SDNStatementStatisticsPage")),
  },
  {
    name: "SDNPowerRankPage",
    path: "/SDNPowerAdmin/SDNPowerRankPage",
    component: Load(import("./SDNPowerRank/SDNPowerRankPage")),
  },
  {
    name: "SDNPowerFlowPage",
    path: "/SDNPowerAdmin/SDNPowerFlowPage",
    component: Load(import("./SDNPowerFlow/SDNPowerFlowPage")),
  },
  {
    name: "SDNPowerPeak",
    path: "/SDNPowerAdmin/SDNPowerPeak",
    component: Load(import("./SDNPowerPeakAnalysis/SDNPowerPeakPage")),
  },
  {
    name: "SDNPeakPage",
    path: "/SDNPowerAdmin/SDNPeakPage",
    component: Load(import("./SDNPeakAnalysis/SDNPeakPage")),
  },
  {
    name: "SDNUnitConfig",
    path: "/SDNPowerAdmin/SDNUnitConfig",
    component: Load(import("./SDNUnitConfig/SDNUnitConfigPage")),
  },
  {
    name: "SDNStandardAnalysis",
    path: "/SDNPowerAdmin/SDNStandardAnalysis",
    component: Load(import("./SDNStandardAnalysis")),
  },
  {
    name: "SDNReportManage",
    path: "/SDNPowerAdmin/SDNReportManage",
    component: Load(import("./SDNReportManage")),
  },
  {
    name: "SDNCompareAnalysis",
    path: "/SDNPowerAdmin/SDNCompareAnalysis",
    component: Load(import("./SDNCompareAnalysis/SDNCompareAnalysisPage")),
  },
  {
    name: "SDNLoadAnalysis",
    path: "/SDNPowerAdmin/SDNDemand",
    component: Load(import("./SDNLoadAnalysis/SDNLoadAnalysisPage")),
  },
  {
    name: "SDNElectricQualityAnalysis",
    path: "/SDNPowerAdmin/SDNElectricQualityAnalysis",
    component: Load(import("./SDNElectricQualityAnalysis")),
  },
  {
    name: "SDNLoadAnalysis",
    path: "/SDNPowerAdmin/SDNLoadAnalysis",
    component: Load(import("./SDNLoadAnalysis/SDNLoadAnalysisPage")),
  },
  {
    name: "SDNCoulometryChargeAnalysis",
    path: "/SDNPowerAdmin/SDNCoulometryChargeAnalysis",
    component: Load(import("./SDNCoulometryChargeAnalysis/SDNCoulometryChargePage")),
  },
  {
    name: "SDNPowerReport",
    path: "/SDNPowerAdmin/SDNPowerReport",
    component: Load(import("./SDNPowerReport/SDNPowerReport")),
  },
  {
    name: "SDNDataInput",
    path: "/SDNPowerAdmin/SDNDataInput",
    component: Load(import("./SDNDataInput/components/SDNDataInputPage")),
  },
  {
    name: "CarbonFootprintView",
    path: "/SDNPowerAdmin/CarbonFootprintView",
    component: Load(import("./CarbonFootprintView")),
  },
  {
    name: "CarbonFootprint",
    path: "/SDNPowerAdmin/CarbonFootprint",
    component: Load(import("./CarbonFootprint")),
  },
  {
    name: "CarbonFootprintFactory",
    path: "/SDNPowerAdmin/CarbonFootprintFactory",
    component: Load(import("./CarbonFootprintFactory")),
  },
  {
    name: "CarbonFootprintArea",
    path: "/SDNPowerAdmin/CarbonFootprintArea",
    component: Load(import("./CarbonFootprintArea")),
  },
  {
    name: "CarbonFootprintRoom",
    path: "/SDNPowerAdmin/CarbonFootprintRoom",
    component: Load(import("./CarbonFootprintRoom")),
  },
  {
    name: "ClassConsumption",
    path: "/SDNPowerAdmin/ClassConsumption",
    component: Load(import("./ClassConsumption")),
  },
  {
    name: "ClassConfig",
    path: "/SDNPowerAdmin/ClassConfig",
    component: Load(import("./ClassConfig")),
  },
  {
    name: "LocationTypeConfig",
    path: "/SDNPowerAdmin/LocationTypeConfig",
    component: Load(import("./LocationTypeConfig")),
  },
  {
    name: "SDNPanelEditor",
    path: "/SDNPowerAdmin/SDNPanelEditor",
    component: Load(import("./SDNPowerEditor/SDNPanelEditor")),
  },
  {
    name: "SDNReportEditor",
    path: "/SDNPowerAdmin/SDNReportEditor",
    component: Load(import("./SDNPowerEditor/SDNReportEditor")),
  },
  {
    name: "BoardConfig",
    path: "/SDNPowerAdmin/BoardConfig",
    component: Load(import("./BoardConfig")),
  },
  {
    name: "ReportConfig",
    path: "/SDNPowerAdmin/ReportConfig",
    component: Load(import("./ReportConfig")),
  },
  {
    name: "ProductionDataServe",
    path: "/SDNPowerAdmin/ProductionDataServe",
    component: Load(import("./ProductionDataServe")),
  },
  {
    name: "CarbonFootprintProcess",
    path: "/SDNPowerAdmin/CarbonFootprintProcess",
    component: Load(import("./CarbonFootprintProcess")),
  },
  {
    name: "CarbonFootprintFront",
    path: "/SDNPowerAdmin/CarbonFootprintFront",
    component: Load(import("./CarbonFootprintFront")),
  },
  {
    name: "CarbonFootprintEnd",
    path: "/SDNPowerAdmin/CarbonFootprintEnd",
    component: Load(import("./CarbonFootprintEnd")),
  },
];

export const routes = routesCfg.map(route => ({ ...route, exact: true }));
