import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";


const SDNEnergyOverview = Loadable({
  loader: () => import("./SDNEnergyOverview"),
  loading: Loading
});

export const routes = [
  {
    path: "/SDNPowerAdmin/SDNPowerOverview",
    exact: true,
    component: SDNEnergyOverview,
    name: "SDNEnergyOverview"
  },
];
