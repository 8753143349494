import React  from 'react';
import { Menu, Layout, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const SubMenu = Menu.SubMenu;
const getRoutePath = ()=>window.location.hash.slice(1);
const getMenuStyle = ()=>({ borderRight:0, height:window.innerHeight-64-152, overflowX:'hidden', overflowY:'auto' });


class Navigation extends React.Component {

    state = { openKeys: [], selectedKey: null };

    componentWillReceiveProps({showSiderBar, menuDataList}) {
        if(showSiderBar===false) {
            this.setState({openKeys: [], selectedKey: null});
            return;
        }
        const openMenuKeys = this.getOpenMenuKeys(menuDataList, getRoutePath());
        if(openMenuKeys && openMenuKeys.length>0){
            const openKeys = openMenuKeys.slice(0, -1);
            const selectedKey = openMenuKeys.slice(-1)[0];
            this.setState({openKeys, selectedKey});
        }
    }

    getOpenMenuKeys = (menuArray, routePath) => {
        if(!(menuArray && menuArray.length > 0)) return null;
        for (const item of menuArray) {
            let menuKeys = [];
            if (item.route === routePath) return [item.key];
            if (item.items && item.items.length > 0) menuKeys = this.getOpenMenuKeys(item.items, routePath);
            if(menuKeys && menuKeys.length>0) return [item.key, ...menuKeys];
        }
    }

    renderSubMenuOrItem = menu => {
        return menu.map(({ title, key, icon, route, items }) => {
            if (items&&items.length>0) return (
                <SubMenu
                    key={key}
                    title={
                        <span>
                            <Icon type={icon || 'no-icon'} antd={'true'} />
                            <span>{title}</span>
                        </span>
                    }
                >
                    { this.renderSubMenuOrItem(items) }
                </SubMenu>
            );

            return (
                <Menu.Item key={key}>
                    <Link to={route||'/'}>
                        <Icon type={icon||'no-icon'} antd={'true'} />
                        <span>{title}</span>
                    </Link>
                </Menu.Item>
            );
        });
    }

    render () {
        return (
            <Layout>
                <Menu
                    theme="dark"
                    mode="inline"
                    style={getMenuStyle()}
                    openKeys={this.state.openKeys}
                    selectedKeys={[this.state.selectedKey]}
                    onOpenChange={openKeys => this.setState({openKeys})}
                    onSelect={({key}) => this.setState({selectedKey: key})}
                >
                    { this.renderSubMenuOrItem(this.props.menuDataList) }
                </Menu>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    menuDataList: state.system.displayMenu,
    showSiderBar: state.layout.showSiderBar
})

export default connect(mapStateToProps)(Navigation);
