import * as actionType from './SDNOperationAdminActionTypes';
import { USER_INFO, GET_USER_INFO } from "../../common/system";
import { sys } from "../../service";
import { archive } from "../../service";
import { service } from "../../service";
import { put, takeLatest, call } from 'redux-saga/effects';

/** ======================================== 集团管理 =====================================*/

function* getGroupInfo(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.GroupService.get, action.groupId);
    yield put({ type: actionType.OPERATION_GROUP_GET_GROUPINFO_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitAddGroup(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.GroupService.addGroup, action.group);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_GROUP_ADD_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}


function* submitEditGroup(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.GroupService.editGroup, action.group);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_GROUP_EDIT_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitDeleteGroup(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.GroupService.del, action.groupId);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_GROUP_DELETE_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

/** ======================================== 企业管理 =====================================*/

function* getEnterpriseInfo(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.ConsumerService.getConsumerInfo, action.enterpriseId);
    yield put({ type: actionType.OPERATION_ENTERPRISE_GET_ENTERPRISEINFO_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getEnergyConfig(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.ConsumerEnergyConfigService.getByOrgId, action.enterpriseId);
    yield put({ type: actionType.OPERATION_ENTERPRISE_GET_ENERGYCONFIG_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getAdminAreas() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.AdminAreaService.getAllData);
    yield put({ type: actionType.OPERATION_ENTERPRISE_GET_ADMIN_AREAS_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getIndustryData() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.IndustryService.getAllData);
    yield put({ type: actionType.OPERATION_ENTERPRISE_GET_INDUSTRYS_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getGroups() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.GroupService.getAll);
    yield put({ type: actionType.OPERATION_ENTERPRISE_GET_GROUPS_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getEnterpriseTypes() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.DictionaryService.getByCategroyCode, "enteType", "");
    yield put({ type: actionType.OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitAddEnterprise(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.ConsumerService.add, action.consumer, action.file);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_ENTERPRISE_ADD_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}


function* submitEditEnterprise(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.ConsumerService.edit, action.consumer, action.file);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_ENTERPRISE_EDIT_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitAddConfig(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.ConsumerEnergyConfigService.add, action.params);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_CONFIG_ADD_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitEditConfig(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.ConsumerEnergyConfigService.edit, action.params);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_CONFIG_EDIT_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitDeleteEnterprise(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.ConsumerService.del, action.enterpriseId);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_ENTERPRISE_DELETE_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

/** ======================================== 用户管理 =====================================*/

function* getUserInfo(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.OrgEmployeeService.get, action.userId);
    yield put({ type: actionType.OPERATION_USER_GET_USERINFO_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getEmpClassifyId() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.DictionaryService.getByCode, "empClassify", "conContact");
    yield put({ type: actionType.OPERATION_USER_GET_EMP_CLASSIFYID_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getAssociateEnterprises() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.AssociateService.fuzzyMatchConsumer, "", "");
    yield put({ type: actionType.OPERATION_USER_GET_ASSOCIATE_ENTERPRISES_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getRoles() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.OrgRoleService.getAll);
    yield put({ type: actionType.OPERATION_USER_GET_ROLES_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitAddUser(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(sys.OrgEmployeeService.addEmpAndRel, action.orgEmployee);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_USER_ADD_SUCCESS });
      yield put({ type: actionType.OPERATION_SHOW_EDIT_USER_ADMIN, payload: false });
    }
    yield put({ type: 'FETCH_SUCCESS' });

  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitEditUser(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(sys.OrgEmployeeService.edit, action.orgEmployee);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_USER_EDIT_SUCCESS });
      yield put({ type: actionType.OPERATION_SHOW_EDIT_USER_ADMIN, payload: false });
      yield put({ type: GET_USER_INFO });
      if (action.isShowUpdatePassword) {
        yield put({ type: actionType.OPERATION_USER_SHOW_UPDATE_PASSWORD, payload: true });
      }
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitUpdateUserPassword(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(sys.OrgEmployeeService.updateEmpPassword, action.aId, action.userId, action.adminPassword, action.userPassword);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_USER_UPDATE_PASSWORD_SUCCESS });
      yield put({ type: actionType.OPERATION_USER_SHOW_UPDATE_PASSWORD, payload: false });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitDeleteUser(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(sys.OrgEmployeeService.delRel, action.userId);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_USER_DELETE_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

/** ======================================== 行业资讯 =====================================*/

function* getNewsTypes() {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.DictionaryService.getByCategroyCode, "newsType");
    yield put({ type: actionType.OPERATION_NEWS_GET_NEWS_TYPES_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getNewsInfo(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(service.NewsService.get, action.newsId);
    yield put({ type: actionType.OPERATION_NEWS_GET_NEWSINFO_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* getNewsFileRecord(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(sys.FileRecordService.get, action.fileId);
    yield put({ type: actionType.OPERATION_NEWS_GET_FILERECORD_SUCCESS, payload: res });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitAddNews(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(service.NewsService.add, action.newsJSON, action.file);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_NEWS_ADD_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitEditNews(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(service.NewsService.edit, action.newsJSON, action.file);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_NEWS_EDIT_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitEditNoFiles(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(service.NewsService.editNoFiles, action.news);
    yield put({ type: actionType.OPERATION_NEWS_EDIT_SUCCESS });
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

function* submitDeleteNews(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(service.NewsService.del, action.newsId);
    if (ret.code === 200) {
      yield put({ type: actionType.OPERATION_NEWS_DELETE_SUCCESS });
    }
    yield put({ type: 'FETCH_SUCCESS' });
  }
  catch (error) {
    yield put({ type: 'FETCH_FAILED', error: error.message });
  }
}

const SDNOperationAdminSagas = [
  takeLatest(actionType.OPERATION_GROUP_GET_GROUPINFO, getGroupInfo),
  takeLatest(actionType.OPERATION_GROUP_ADD, submitAddGroup),
  takeLatest(actionType.OPERATION_GROUP_EDIT, submitEditGroup),
  takeLatest(actionType.OPERATION_GROUP_DELETE, submitDeleteGroup),

  takeLatest(actionType.OPERATION_ENTERPRISE_GET_ENTERPRISEINFO, getEnterpriseInfo),
  takeLatest(actionType.OPERATION_ENTERPRISE_GET_ENERGYCONFIG, getEnergyConfig),
  takeLatest(actionType.OPERATION_ENTERPRISE_GET_ADMIN_AREAS, getAdminAreas),
  takeLatest(actionType.OPERATION_ENTERPRISE_GET_INDUSTRYS, getIndustryData),
  takeLatest(actionType.OPERATION_ENTERPRISE_GET_GROUPS, getGroups),
  takeLatest(actionType.OPERATION_ENTERPRISE_GET_ENTERPRISE_TYPES, getEnterpriseTypes),
  takeLatest(actionType.OPERATION_ENTERPRISE_ADD, submitAddEnterprise),
  takeLatest(actionType.OPERATION_ENTERPRISE_EDIT, submitEditEnterprise),
  takeLatest(actionType.OPERATION_CONFIG_ADD, submitAddConfig),
  takeLatest(actionType.OPERATION_CONFIG_EDIT, submitEditConfig),
  takeLatest(actionType.OPERATION_ENTERPRISE_DELETE, submitDeleteEnterprise),

  takeLatest(actionType.OPERATION_USER_GET_USERINFO, getUserInfo),
  takeLatest(actionType.OPERATION_USER_GET_EMP_CLASSIFYID, getEmpClassifyId),
  takeLatest(actionType.OPERATION_USER_GET_ASSOCIATE_ENTERPRISES, getAssociateEnterprises),
  takeLatest(actionType.OPERATION_USER_GET_ROLES, getRoles),
  takeLatest(actionType.OPERATION_USER_ADD, submitAddUser),
  takeLatest(actionType.OPERATION_USER_EDIT, submitEditUser),
  takeLatest(actionType.OPERATION_USER_UPDATE_PASSWORD, submitUpdateUserPassword),
  takeLatest(actionType.OPERATION_USER_DELETE, submitDeleteUser),

  takeLatest(actionType.OPERATION_NEWS_GET_NEWS_TYPES, getNewsTypes),
  takeLatest(actionType.OPERATION_NEWS_GET_NEWSINFO, getNewsInfo),
  takeLatest(actionType.OPERATION_NEWS_GET_FILERECORD, getNewsFileRecord),
  takeLatest(actionType.OPERATION_NEWS_ADD, submitAddNews),
  takeLatest(actionType.OPERATION_NEWS_EDIT, submitEditNews),
  takeLatest(actionType.OPERATION_NEWS_EDIT_NOFILE, submitEditNoFiles),
  takeLatest(actionType.OPERATION_NEWS_DELETE, submitDeleteNews),

];

export default SDNOperationAdminSagas;
