import loadable from "react-loadable";
import loading from "../../common/ui/components/Loading";

const Load = component => loadable({ loader: () => component, loading });

export const routes = [
  {
    exact: true,
    name: "AlarmRecord",
    path: "/SDNEventAlert/AlarmRecord",
    component: Load(import("./AlarmRecord")),
  },
  {
    exact: true,
    name: "SendRecord",
    path: "/SDNEventAlert/SendRecord",
    component: Load(import("./SendRecord")),
  },
  {
    exact: true,
    name: "RuleConfig",
    path: "/SDNEventAlert/RuleConfig",
    component: Load(import("./RuleConfig")),
  },
  {
    exact: true,
    name: "SendConfig",
    path: "/SDNEventAlert/SendConfig",
    component: Load(import("./SendConfig")),
  },
  {
    exact: true,
    name: "SDNEventAlert",
    path: "/SDNEventAlert/SDNEventAlert",
    component: Load(import("./components/SDNEventAlert")),
  },
  {
    exact: true,
    name: "SDNAlertConfig",
    path: "/SDNAlertConfig/SDNAlertConfig",
    component: Load(import("./components/SDNAlertConfig")),
  },
  {
    exact: true,
    name: "SDNRTAlertMonitor",
    path: "/SDNAlertConfig/SDNRTAlertMonitor",
    component: Load(import("./components/SDNRTAlertMonitor")),
  },
];
