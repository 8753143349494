import app from './app';
import archive from './archive';
import efficiency from './efficiency';
import alarm from './alarm';
import operation from './operation';
import personal from './personal';
import service from './service';
import sys from './sys';
import user from './user';
import events from './events';
import yunshui from './yunshui';
import hunan from './hunan';
import demand from './demand';

export { app, archive, efficiency, alarm, operation, personal, service, sys, user, events, yunshui, hunan, demand };
