import { user, archive } from "../../../service";
import {put, takeLatest, call, all} from 'redux-saga/effects';
import moment from 'moment';
import { notification } from "antd";
import { errorBox, Utils, infoBox } from "../../../common";

export const LOAD_MONITOR_OBJECTS = 'MONITOR_LOAD_MONITOR_OBJECTS';
const LOADING_MONITOR_OBJECTS = 'MONITOR_LOADING_MONITOR_OBJECTS';
export const LOAD_MONITOR_OBJECTS_SUCCESS = 'MONITOR_LOAD_MONITOR_OBJECTS_SUCCESS';
export const SHOW_MONITOR_OBJECTS = 'MONITOR_SHOW_MONITOR_OBJECTS';
//设备
export const SHOW_DEVICE_DETAIL = 'MONITOR_SHOW_DEVICE_DETAIL';
export const GET_DEVICE_DATA_ITEMS = 'MONITOR_GET_DEVICE_DATA_ITEMS'
const GET_DEVICE_DATA_ITEMS_SUCCESS = 'MONITOR_GET_DEVICE_DATA_ITEMS_SUCCESS'
const SET_DEVICE_INFO = 'MONITOR_SET_DEVICE_INFO'
export const LOAD_DEVICE_DETAIL_DATA = 'MONITOR_LOAD_DEVICE_DETAIL_DATA';
export const LOAD_DEVICE_DETAIL_DATA_SUCCESS = 'MONITOR_LOAD_DEVICE_DETAIL_DATA_SUCCESS';
//监控点
export const SET_POINT = 'MONITOR_SET_POINT';
const LOADING_POINT_CHART = 'MONITOR_LOADING_POINT_CHART';
export const SHOW_POINT_CHART = 'MONITOR_SHOW_POINT_CHART';
export const CLEAR_POINT_CHART = 'MONITOR_CLEAR_POINT_CHART';
export const LOAD_POINT_CHART_DATA = 'MONITOR_LOAD_POINT_CHART_DATA';
export const LOAD_POINT_CHART_DATA_SUCCESS = 'MONITOR_LOAD_POINT_CHART_DATA_SUCCESS';
//电表
export const SHOW_AMMETER_CHART = 'MONITOR_SHOW_AMMETER_CHART';
export const SET_AMMETER = 'MONITOR_SET_AMMETER';
export const LOAD_AMMETER_CHART_DATA = 'MONITOR_LOAD_AMMETER_CHART_DATA';
export const LOAD_AMMETER_CHART_DATA_SUCCESS = 'MONITOR_LOAD_AMMETER_CHART_DATA_SUCCESS';
export const LOADING_AMMETER_CHART = 'MONITOR_LOADING_AMMETER_CHART';
export const CLEAR_AMMETER_CHART = 'MONITOR_CLEAR_AMMETER_CHART';

export function* loadMonitorObjects (action) {
    yield put({ type: LOADING_MONITOR_OBJECTS, payload: true });
    const ret = yield call(user.UserMonitorSiteService.getMonitorSiteDetails, action.payload);
    yield put({ type: LOADING_MONITOR_OBJECTS, payload: false });
    if (!ret.code) {
        yield put({ type: LOAD_MONITOR_OBJECTS_SUCCESS, payload: ret });
    }
}

export function* getDeviceDataItems (action) {
    const { monitorObjectId, classifiedType, itemName } = action.payload;
    if (Utils.empty(monitorObjectId) || Utils.empty(classifiedType)) {
        return;
    }
    const ret = yield call(archive.DataItemService.getByPrimaryDevice, monitorObjectId, classifiedType);
    if (!ret.code) {
        if (Utils.empty(ret)) {
            infoBox("未获取到设备数据项");
        }
        yield put({ type: GET_DEVICE_DATA_ITEMS_SUCCESS, payload: { itemName, deviceDataItems: ret } });
    }
}

export function* loadDeviceDetailData (action) {
    const ret = yield call(
        user.UserMonitorObjectService.objDetails,
        action.payload
    );
    
    if (!ret.code) {
        yield put({ type: LOAD_DEVICE_DETAIL_DATA_SUCCESS, payload: ret });
    }
    
}

export function* loadPointChartData (action) {
    const {
        monitorItemId,
        dataType,
        beginDate,
        endDate,
        interval
    } = action.payload;
    yield put({ type: LOADING_POINT_CHART, payload: true });
    const ret = yield call(
        user.UserMonitorItemService.getHistoryDatas,
        monitorItemId,
        dataType,
        beginDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD HH:mm:ss'),
        interval
    );
    yield put({ type: LOADING_POINT_CHART, payload: false });
    if (!ret.code) {
        yield put({ type: LOAD_POINT_CHART_DATA_SUCCESS, payload: ret });
    }
}

export function* loadAmmeterChartData (action) {
    const {
        ammeterId,
        onePreps,
        beginDate,
        endDate,
        interval
    } = action.payload;
    yield put({ type: LOADING_AMMETER_CHART, payload: true });
    let begin = beginDate.format('YYYY-MM-DD HH:mm:ss');
    let end = endDate.format('YYYY-MM-DD HH:mm:ss');
    if (interval === 115) {
        begin = beginDate.format('YYYY-MM-DD HH') + ':00:00';
        end = endDate.format('YYYY-MM-DD HH:mm:ss');
    }
    else if (interval === 201) {
        begin = beginDate.format('YYYY-MM-DD HH') + ':00';
        end = endDate.format('YYYY-MM-DD HH:mm:ss');
    }
    else if (interval === 301) {
        begin = beginDate.format('YYYY-MM-DD');
        end = endDate.format('YYYY-MM-DD');
    }
    else if (interval === 501) {
        begin = beginDate.format('YYYY-MM');
        end = endDate.format('YYYY-MM');
    }
    
    const ret = yield call(
        user.UserAmmeterService.ammeterHistorDatas,
        ammeterId,
        onePreps,
        begin,
        end,
        interval
    );
    yield put({ type: LOADING_AMMETER_CHART, payload: false });

    if (!ret.code) {
        yield put({ type: LOAD_AMMETER_CHART_DATA_SUCCESS, payload: { type: onePreps, data: ret } });
    }
}

export const monitorChartSagas = [
    takeLatest(LOAD_MONITOR_OBJECTS, loadMonitorObjects),
    takeLatest(GET_DEVICE_DATA_ITEMS, getDeviceDataItems),
    takeLatest(LOAD_DEVICE_DETAIL_DATA, loadDeviceDetailData),
    takeLatest(LOAD_POINT_CHART_DATA, loadPointChartData),
    takeLatest(LOAD_AMMETER_CHART_DATA, loadAmmeterChartData)
];

export function SDNMonitorChartReducer (
    state = {
        showMonitorObjects: false,
        loadingMonitorObjects: false,
        monitorObjects: {},
        itemName: "",
        //设备
        showDeviceDetail: false,
        deviceDataItems: [],
        deviceDetail: {},
        //监控点
        showPointChart: false,
        loadingPointChart: false,
        point: {},
        pointChart: {},
        //电表
        showAmmeterChart: false,
        loadingAmmeterChart: false,
        ammeter: {},
        ammeterChart: {
            PP: [], //电量
            PZ: [], //总有功功率
            QZ: [], //总无功功率
            E: [], //电流
            V: [], //电压
            FZ: [] //功率因数
        }
    },
    action
) {
    switch (action.type) {

        case LOADING_MONITOR_OBJECTS: {
            return { ...state, loadingMonitorObjects: action.payload };
        }

        case LOAD_MONITOR_OBJECTS_SUCCESS: {
            return { ...state, monitorObjects: action.payload };
        }

        case SHOW_MONITOR_OBJECTS: {
            return { ...state, showMonitorObjects: action.payload };
        }

        case GET_DEVICE_DATA_ITEMS_SUCCESS: {
            return { ...state, showDeviceDetail: true, ...action.payload }
        }

        case SHOW_DEVICE_DETAIL: {
            if (action.payload === false) {
                return { ...state, showDeviceDetail: action.payload, deviceDataItems: [], pointChart: {} };
            }
            return { ...state, showDeviceDetail: action.payload };
        }

        case LOAD_DEVICE_DETAIL_DATA_SUCCESS: {
            return { ...state, deviceDetail: action.payload };
        }

        case LOADING_POINT_CHART: {
            return { ...state, loadingPointChart: action.payload };
        }

        case SET_POINT: {
            return { ...state, point: action.payload };
        }

        case SHOW_POINT_CHART: {
            return { ...state, showPointChart: action.payload };
        }

        case CLEAR_POINT_CHART: {
            return { ...state, point: {}, pointChart: {}}
        }

        case LOAD_POINT_CHART_DATA_SUCCESS: {
            return { ...state, pointChart: action.payload };
        }

        case SHOW_AMMETER_CHART: {
            return { ...state, showAmmeterChart: action.payload };
        }

        case SET_AMMETER: {
            return { ...state, ammeter: action.payload };
        }

        case LOAD_AMMETER_CHART_DATA_SUCCESS: {
            const { type, data } = action.payload;
            let ammeterChart = { ...state.ammeterChart };
            if (type === 'IA,IB,IC') {
                ammeterChart['E'] = data;
            }
            else if (type === 'UA,UB,UC') {
                ammeterChart['V'] = data;
            }
            else {
                ammeterChart[type] = data;
            }
            return { ...state, ammeterChart: ammeterChart };
        }

        case LOADING_AMMETER_CHART: {
            return { ...state, loadingAmmeterChart: action.payload };
        }

        case CLEAR_AMMETER_CHART: {
            return { ...state, ammeterChart: {
                PP: [], //电量
                PZ: [], //总有功功率
                QZ: [], //总无功功率
                E: [], //电流
                V: [], //电压
                FZ: [] //功率因数
            }}
        }

        default:
            return state;
    }
}

