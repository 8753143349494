import { BaseService } from '../SDNRequest';

const demand = {
	    //响应能力配置	    
        ResponseCapacityService :{
			
			//增加响应能力配置对象
			add : function(mapParams) {
						return BaseService.post('/api/demand/responseCapacity/add',mapParams);
			},
			
			//计算负荷
			applyPzCalc : function(orgId, runDay, ids, states) {
				return BaseService.post('/api/demand/responseCapacity/applyPzCalc',{
						'orgId':orgId, 'runDay':runDay, 'ids':ids, 'states':states
					});
			},
			
			//删除响应能力配置对象
			del : function(id) {
				return BaseService.post('/api/demand/responseCapacity/del',{
						'id':id
					});
			},
			
			//修改响应能力配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/demand/responseCapacity/edit',mapParams);
			},
			
			//修改响应能力配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/demand/responseCapacity/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出响应能力配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/demand/responseCapacity/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//导出监控对象的响应能力信息
			exportResponseCapacitys : function(orgId, runDay) {
				return BaseService.download('/api/demand/responseCapacity/exportResponseCapacitys',{
						'orgId':orgId, 'runDay':runDay
					});
			},
			
			//根据ID获取响应能力配置对象
			get : function(id) {
				return BaseService.get('/api/demand/responseCapacity/get',{
						'id':id
					});
			},
			
			//查询所有响应能力配置对象
			getAll : function() {
				return BaseService.get('/api/demand/responseCapacity/getall');
			},
			
			//DataTable查询响应能力配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/demand/responseCapacity/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示响应能力配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/demand/responseCapacity/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据orgId获取监控对象的响应能力信息
			getResponseCapacityByOrgId : function(orgId, runDay) {
				return BaseService.get('/api/demand/responseCapacity/getResponseCapacityByOrgId',{
						'orgId':orgId, 'runDay':runDay
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/demand/responseCapacity/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
			
			//更新响应时段实时负荷信息
			updateResponseTimes : function(orgId, dataAmmeterId, runDay) {
				return BaseService.post('/api/demand/responseCapacity/updateResponseTimes',{
						'orgId':orgId, 'dataAmmeterId':dataAmmeterId, 'runDay':runDay
					});
			},
		},
	    //响应方案	    
        ResponsePlanService :{
			
			//增加响应方案对象
			add : function(mapParams) {
						return BaseService.post('/api/demand/responsePlan/add',mapParams);
			},
			
			//删除响应方案对象
			del : function(id) {
				return BaseService.post('/api/demand/responsePlan/del',{
						'id':id
					});
			},
			
			//修改响应方案对象
			edit : function(mapParams) {
						return BaseService.post('/api/demand/responsePlan/edit',mapParams);
			},
			
			//修改响应方案对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/demand/responsePlan/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出响应方案对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/demand/responsePlan/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取响应方案对象
			get : function(id) {
				return BaseService.get('/api/demand/responsePlan/get',{
						'id':id
					});
			},
			
			//查询所有响应方案对象
			getAll : function() {
				return BaseService.get('/api/demand/responsePlan/getall');
			},
			
			//DataTable查询响应方案对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/demand/responsePlan/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示响应方案对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/demand/responsePlan/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取调整模式列表
			getPlanTypeList : function() {
				return BaseService.get('/api/demand/responsePlan/getPlanTypeList');
			},
			
			//根据orgId获取监控对象的响应方案
			getResponsePlanByOrgId : function(orgId) {
				return BaseService.get('/api/demand/responsePlan/getResponsePlanByOrgId',{
						'orgId':orgId
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/demand/responsePlan/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //响应时段	    
        ResponseTimeService :{
			
			//增加响应时段对象
			add : function(mapParams) {
						return BaseService.post('/api/demand/responseTime/add',mapParams);
			},
			
			//选择响应时段
			applyResponseTimes : function(orgIds, runDay, responseTimes) {
				return BaseService.post('/api/demand/responseTime/applyResponseTimes',{
						'orgIds':orgIds, 'runDay':runDay, 'responseTimes':responseTimes
					});
			},
			
			//删除响应时段对象
			del : function(id) {
				return BaseService.post('/api/demand/responseTime/del',{
						'id':id
					});
			},
			
			//修改响应时段对象
			edit : function(mapParams) {
						return BaseService.post('/api/demand/responseTime/edit',mapParams);
			},
			
			//修改响应时段对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/demand/responseTime/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出响应时段对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/demand/responseTime/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//导出监控对象的计划响应容量信息
			exportResponseCapacitys : function(orgId, orgName, runDay) {
				return BaseService.download('/api/demand/responseTime/exportResponseTimes',{
						'orgId':orgId, 'orgName':orgName, 'runDay':runDay
					});
			},
			
			//导出监控对象的实际响应容量信息
			exportResponseCapacitys2 : function(orgId, orgName, runDay) {
				return BaseService.download('/api/demand/responseTime/exportResponseTimes2',{
						'orgId':orgId, 'orgName':orgName, 'runDay':runDay
					});
			},
			
			//根据ID获取响应时段对象
			get : function(id) {
				return BaseService.get('/api/demand/responseTime/get',{
						'id':id
					});
			},
			
			//查询所有响应时段对象
			getAll : function() {
				return BaseService.get('/api/demand/responseTime/getall');
			},
			
			//获取基线负荷
			getBaselinePZ : function(dataAmmeterId, runDay) {
				return BaseService.get('/api/demand/responseTime/getBaselinePZ',{
						'dataAmmeterId':dataAmmeterId, 'runDay':runDay
					});
			},
			
			//DataTable查询响应时段对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/demand/responseTime/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示响应时段对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/demand/responseTime/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取监控对象的响应信息
			getResponseInfo : function(orgId, runDay) {
				return BaseService.get('/api/demand/responseTime/getResponseInfo',{
						'orgId':orgId, 'runDay':runDay
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/demand/responseTime/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
			
			//保存第二天的响应时段信息
			saveResponseTimes : function(orgIds, dataAmmeterIds, runDay, compareDay) {
				return BaseService.post('/api/demand/responseTime/saveResponseTimes',{
						'orgIds':orgIds, 'dataAmmeterIds':dataAmmeterIds, 'runDay':runDay, 'compareDay':compareDay
					});
			},
		},
 
}

export default demand;