import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./config/configureStore";
import { authRoutes } from "./service/routes";
import { Layout } from "./layout";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import { ConfigProvider } from "antd";
import "./index.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <ConfigProvider locale={zh_CN}>
            <Switch>
              {authRoutes.map((route, idx) => {
                return route.component ? (
                  <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => <route.component {...props} />} />
                ) : null;
              })}
              <Route path="/" name="Home" component={Layout} />
            </Switch>
          </ConfigProvider>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
