import React from "react";
import { accountType } from "../../config";
import { serverUrl } from '../../service';
import SDNFirmSelect from "../../common/ui/components/SDNFirmSelect";
import { connect } from 'react-redux';
import { SET_SELECTED_FIRM, GET_FIRM_SUB_TREE } from '../../common/system';
import Flex, { FlexItem } from 'styled-flex-component';
import { GET_SITE_LIST_BY_ID } from "../../views/SDNEventAlert/SDNEventAlertAcitonType";
import { Layout } from 'antd';

const { Header } = Layout;

class SDNHeader extends React.Component {

  imgOnError = (e) => {
    let img = e.target;
    img.src = "assets/img/hxy/logo.png";
  }

  handleSelectedCompany = firm => {
    this.props.getAlertSiteListById(firm.orgId);
    this.props.getFirmSubTree(firm.orgId)
  }

  renderCompanySelector = () => {
    const { user, firmList } = this.props;
    if (user.accountType == accountType.normal && firmList && firmList.length > 0) {
      return (
        <Flex style={{ width: 280, marginLeft: 20, display: (firmList.length == 1 ? 'none' : 'inline-block') }}>
          <SDNFirmSelect onSelectedFirm={firm => this.handleSelectedCompany(firm)} />
        </Flex>
      );
    }
    return null;
  }

  renderLogoTitle = () => {
    const { user, userAdmin, firmList } = this.props;
    if (user.accountType == accountType.normal) {
      let title = '';
      let img = '';
      if (userAdmin.groupFirms.group) {
        title = userAdmin.groupFirms.group.gName;
        img = serverUrl + userAdmin.groupFirms.group.gFilePath;
      }
      else if (firmList.length > 0) {
        const firm = firmList[0];
        title = firm.orgName;
        img = serverUrl + firm.logo;
      }
      return (
        <Flex alignCenter style={{ marginRight: 20 }}>
          <img
            src={img}
            alt={title}
            title={title}
            onError={e => this.imgOnError(e)}
          />
        </Flex>
      )
    }
    else {
      return (
        <img
          src={"assets/img/hxy/logo.png"}
          alt={"中华商务综合能源管理平台"}
          title={"中华商务综合能源管理平台"}
        />
      )
    }
  }

  render() {
    return (
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', background: '#FFF', padding: 0, height: 64 }}>
        <Flex full alignCenter style={{ paddingLeft: 15, paddingRight: 30 }} justifyBetween>
          <Flex alignCenter>
            {this.renderLogoTitle()}
            {this.renderCompanySelector()}
          </Flex>
          {/*TODO:隐藏头部告警*/}
          {/*<Activities/>*/}
        </Flex>
      </Header>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.system.user,
    firmList: state.system.firmList,
    selectedFirm: state.system.selectedFirm,
    userAdmin: state.userAdmin,
    alarmCount: 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedFirm: company => dispatch({ type: SET_SELECTED_FIRM, payload: company }),
    getFirmSubTree: objectId => dispatch({ type: GET_FIRM_SUB_TREE, payload: objectId }),
    // getObjectTree: objectId => dispatch({type: GET_OBJECT_TREE, payload: objectId}),
    getAlertSiteListById: enterpriseId => dispatch({
      type: GET_SITE_LIST_BY_ID,
      payload: enterpriseId
    }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SDNHeader);
