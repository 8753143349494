import { efficiency, archive } from "../../../service";
import { put, takeLatest, call } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';

export const DEVICE_LIST = 'DEVICE_LIST';
export const DEVICE_LIST_SUCCESS = 'DEVICE_LIST_SUCCESS';

export const GetImportantAnalysisData = 'GetImportantAnalysisData';
export const GetImportantAnalysisData_SUCCESS = 'GetImportantAnalysisData_SUCCESS';

function* getDeviceList(action) {
    const {
        consumerId,
        typeCode,
    } = action.payload;
    const ret = yield call(
        archive.MonitorObjectService.getByConsumerIdAndTypeCode, 
        consumerId,
        typeCode
    );
    if (!ret.code) {
        yield put({ type: DEVICE_LIST_SUCCESS, payload: ret });
    }
}

function* getAirCtrlEfficiencyAnalysis(action) {
    const {
        orgId, 
        beginDate, 
        endDate, 
        interval,
        typeCode,
    } = action.payload;

    let fun = null;
    if (typeCode == 'ZL') {
        fun = efficiency.ImportantAnalysisService.getAirCtrlEfficiencyAnalysis;
    } else if (typeCode == 'K011') {
        fun = efficiency.ImportantAnalysisService.getAirCtrlDataMonitor;
    } else if (typeCode == 'KY') {
        fun = efficiency.ImportantAnalysisService.getAirZipEfficiencyAnalysis;
    } else if (typeCode == 'Q011') {
        fun = efficiency.ImportantAnalysisService.getAirZipDataMonitor;
    }

    const ret = yield call(
        fun, 
        orgId, 
        beginDate, 
        endDate, 
        interval
    );
    if (!ret.code) {
        yield put({ type: GetImportantAnalysisData_SUCCESS, payload: ret });
    }
}
 

export  const AirCtrlEfficiencyAnalysisSagas = [
    takeLatest(DEVICE_LIST, getDeviceList),
    takeLatest(GetImportantAnalysisData, getAirCtrlEfficiencyAnalysis),    
];


const initalState = {
    deviceList: [],
    chartData: [],
}

export const AirCtrlEfficiencyAnalysisReducer = handleActions({

    DEVICE_LIST_SUCCESS: (state, action) => {
        let deviceListData = action.payload;
        if (deviceListData && deviceListData.length > 0) {
            deviceListData.map(item=> {
                item.name = item.orgName;
                item.value = item.orgId;
            });
        }

        return {
            ...state,
            deviceList: deviceListData || [],
        };
    },

    GetImportantAnalysisData_SUCCESS: (state, action) => {
        return {
            ...state,
            chartData: action.payload || [],
        };
    },

}, initalState);
