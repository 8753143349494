import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
import { Utils } from "../../utils";
moment.locale("zh-cn");

export const datePickerType = { time: 0, day: 1, week: 2, month: 3, year: 4, any: 5 };

export default class SDNDatePicker extends React.Component {
  format = "YYYY-MM-DD HH:mm";
  static defaultProps = {
    style: {},
    dateType: datePickerType.day, //0 时间 1 日 2 周 3 月 4 年 5 任意
    defaultDate: null,
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false, date: props.defaultDate == null ? moment() : null };
  }

  componentWillReceiveProps({ defaultDate }) {
    this.setState({ date: defaultDate || null });
  }

  resetDate = () => {
    this.setState({ date: moment() });
  };

  handleChange = (date, dateString) => {
    if (Utils.empty(date)) {
      return;
    }
    this.setState({ date: date });
    if (this.props.onChange) {
      this.props.onChange(date, dateString);
    }
  };

  handleTimeChange = date => {
    if (Utils.empty(date)) {
      return;
    }
    this.setState({ date: date });
    if (this.props.onChange) {
      this.props.onChange(date, date.format(this.format));
    }
  };

  handlePanelChange = date => {
    if (Utils.empty(date)) {
      return;
    }
    this.setState({ isOpen: false, date: date });
    if (this.props.onChange) {
      this.props.onChange(date, date.format(this.format));
    }
  };

  handleOpenChange = status => {
    if (status) {
      this.setState({ isOpen: true });
    } else {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { dateType } = this.props;

    let mode = "time";
    if (dateType === datePickerType.day) {
      this.format = "YYYY-MM-DD";
      mode = "date";
    } else if (dateType === datePickerType.week) {
      this.format = "YYYY-MM-DD";
      return (
        <DatePicker.WeekPicker
          locale={locale}
          value={this.state.date}
          format={this.format}
          allowClear={false}
          onChange={(date, dateString) => this.handleChange(date, dateString)}
        />
      );
    } else if (dateType === datePickerType.month) {
      this.format = "YYYY-MM";
      mode = "month";
    } else if (dateType === datePickerType.year) {
      this.format = "YYYY";
      mode = "year";
    } else if (dateType === datePickerType.any) {
      this.format = "YYYY-MM-DD HH:mm";
      mode = "date";
    }

    return (
      <DatePicker
        style={this.props.style}
        suffixIcon={this.props.suffixIcon}
        allowClear={!!this.props.allowClear}
        placeholder={this.props.placeholder}
        locale={locale}
        value={this.state.date}
        open={this.state.isOpen}
        format={this.format}
        mode={mode}
        onOpenChange={status => this.handleOpenChange(status)}
        onOk={value => this.handleTimeChange(value)}
        onChange={(date, dateString) => this.handleChange(date, dateString)}
        onPanelChange={date => this.handlePanelChange(date)}
      />
    );
  }
}
